<template>
  <div class="flex-grow flex flex-col gap-8">
    <div class="p-6 lg:p-12">
      <h2 class="h2 text-primary mt-0">{{ getTranslationByKey('Anmelden') }}</h2>
      <div
        v-if="registerSuccess"
        class="bg-successColor text-white rounded-lg px-2 py-1 w-[98%] flex items-center gap-2 text-xs md:text-base">
        <InformationCircleIcon class="w-16" />
        {{ getTranslationByKey('Zur Aktivierung Ihrer Registrierung') }}
      </div>
      <form
        style="--gap: 1rem"
        id="user-login"
        class="space-y-6 flex flex-col"
        accept-charset="UTF-8"
        action="actions/users/login"
        method="post">
        <input type="hidden" :name="csrfName" :value="csrfToken" />
<!--        <input type="hidden" name="action" value="users/login" />-->
        <div class="flex items-center gap-[var(--gap)] flex-wrap">
          <div
            class="gap-3 w-full md:w-[calc(50%-var(--gap))] flex flex-col minimal-input">
            <v-text-field
              v-model="email"
              type="email"
              name="loginName"
              autocomplete="email"
              single-line
              :label="`${getTranslationByKey('E-Mail')}*`"
            ></v-text-field>
          </div>
          <div
            class="gap-3 w-full md:w-[calc(50%-var(--gap))] flex flex-col minimal-input"
          >
            <v-text-field
              name="password"
              autocomplete="password"
              v-model="password"
              :rules="[rules.required]"
              single-line
              :label="`${getTranslationByKey('Passwort')}*`"
              type="password"
            ></v-text-field>
          </div>
        </div>
        <div>
          <v-btn-transparent
            @click="$emit('change', 'reset')"
            class="text-xs underline focus:text-primary"
            >{{ getTranslationByKey('Passwort vergessen') }}</v-btn-transparent
          >
        </div>

        <div>
          <v-checkbox :label="`${ getTranslationByKey('angemeldet bleiben')}`"></v-checkbox>
        </div>
        <v-btn
          id="submitLogin"
          class="w-full lg:w-[calc(50%-var(--gap))]"
          type="submit"
          :disabled="!password || password.length < 8 || !email"
          focused
          >{{ getTranslationByKey('Anmelden') }}</v-btn
        >
      </form>
    </div>
    <div
      class="bg-primary w-full flex-grow rounded-t-lg text-white p-6 lg:p-12 space-y-8"
    >
      <h2 class="h2-s mt-0">{{ getTranslationByKey('Noch kein Mitglied?') }}</h2>
      <h3 class="uppercase">{{ getTranslationByKey('Darum lohnt sich die Meisterstück-HAUS-Community') }}</h3>
      <ul
        style="--gap: 2rem"
        class="space-y-3 lg:flex lg:gap-[var(--gap)] lg:flex-wrap lg:items-start lg:space-y-0 gap-4"
      >
        <li
          class="lg:w-[calc(50%-var(--gap))] lg:flex-grow whitespace-break-spaces flex items-center gap-2"
        >
          <span class="text-white icon icon-s">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.5 12.75L10.5 18.75L19.5 5.25"
                stroke="currentColor"
                fill="none"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <span class="max-w-[90%]" v-text="`${getTranslationByKey('Merkliste & Datenblatt-Download')}`"></span>
        </li>
        <li
          class="lg:w-[calc(50%-var(--gap))] lg:flex-grow whitespace-break-spaces flex items-center gap-2"
        >
          <span class="text-white icon icon-s">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.5 12.75L10.5 18.75L19.5 5.25"
                stroke="currentColor"
                fill="none"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <span class="max-w-[90%]" v-text="`${getTranslationByKey('exklusive Angebote')}`"></span>
        </li>
        <li
          class="lg:w-[calc(50%-var(--gap))] lg:flex-grow whitespace-break-spaces flex items-center gap-2"
        >
          <span class="text-white icon icon-s">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.5 12.75L10.5 18.75L19.5 5.25"
                stroke="currentColor"
                fill="none"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <span class="max-w-[90%]" v-text="`${getTranslationByKey('kostenloser HAUS-Katalog')}`"></span>
        </li>
        <li
          class="lg:w-[calc(50%-var(--gap))] lg:flex-grow whitespace-break-spaces flex items-center gap-2"
        >
          <span class="text-white icon icon-s">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.5 12.75L10.5 18.75L19.5 5.25"
                stroke="currentColor"
                fill="none"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <span class="max-w-[90%]" v-text="`${getTranslationByKey('persönliche Berater')}`"></span>
        </li>
        <li
          class="lg:w-[calc(50%-var(--gap))] lg:flex-grow whitespace-break-spaces flex items-center gap-2"
        >
          <span class="text-white icon icon-s">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.5 12.75L10.5 18.75L19.5 5.25"
                stroke="currentColor"
                fill="none"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <span class="max-w-[90%]" v-text="`${getTranslationByKey('kostenlose Downloads')}`"></span>
        </li>
      </ul>
      <v-btn-lite-dark @click="$emit('change', 'register')" class="!text-white"
        >{{ getTranslationByKey('Jetzt registrieren') }}</v-btn-lite-dark
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { loaded } from "@/stores/loading";
import { ref, onMounted } from "vue";
import { userClass } from "@/api/class/userApiClass";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { getTranslationByKey } from "@/stores/translation";
const client = new userClass();

const props = defineProps<{
  registerSuccess: boolean;
}>();

const csrfName = ref("");
const csrfToken = ref("");

const password = ref("");
const email = ref("");

const show = ref(false);
const emit = defineEmits(["change"]);

const rules = {
  required: (value) => !!value || getTranslationByKey('Pflichtfeld'),
};

onMounted(() => {
  csrfName.value = window.csrfTokenName;
  csrfToken.value = window.csrfTokenValue;

  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);

  document.getElementById("submitLogin").focus();
});
</script>
